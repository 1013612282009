exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-me-index-js": () => import("./../../../src/pages/contact-me/index.js" /* webpackChunkName: "component---src-pages-contact-me-index-js" */),
  "component---src-pages-csci-5611-index-js": () => import("./../../../src/pages/csci5611/index.js" /* webpackChunkName: "component---src-pages-csci-5611-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-k-means-index-js": () => import("./../../../src/pages/k-means/index.js" /* webpackChunkName: "component---src-pages-k-means-index-js" */),
  "component---src-pages-ludum-dare-index-js": () => import("./../../../src/pages/ludum-dare/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-index-js" */),
  "component---src-pages-ludum-dare-ludum-dare-34-index-js": () => import("./../../../src/pages/ludum-dare/ludum-dare-34/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-ludum-dare-34-index-js" */),
  "component---src-pages-ludum-dare-ludum-dare-35-index-js": () => import("./../../../src/pages/ludum-dare/ludum-dare-35/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-ludum-dare-35-index-js" */),
  "component---src-pages-ludum-dare-ludum-dare-37-index-js": () => import("./../../../src/pages/ludum-dare/ludum-dare-37/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-ludum-dare-37-index-js" */),
  "component---src-pages-ludum-dare-ludum-dare-38-index-js": () => import("./../../../src/pages/ludum-dare/ludum-dare-38/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-ludum-dare-38-index-js" */),
  "component---src-pages-ludum-dare-ludum-dare-40-index-js": () => import("./../../../src/pages/ludum-dare/ludum-dare-40/index.js" /* webpackChunkName: "component---src-pages-ludum-dare-ludum-dare-40-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-motion-island-index-js": () => import("./../../../src/pages/projects/motion-island/index.js" /* webpackChunkName: "component---src-pages-projects-motion-island-index-js" */),
  "component---src-pages-projects-motion-island-report-index-js": () => import("./../../../src/pages/projects/motion-island/report/index.js" /* webpackChunkName: "component---src-pages-projects-motion-island-report-index-js" */),
  "component---src-pages-projects-quotagram-index-js": () => import("./../../../src/pages/projects/quotagram/index.js" /* webpackChunkName: "component---src-pages-projects-quotagram-index-js" */),
  "component---src-pages-projects-vr-escaperoom-index-js": () => import("./../../../src/pages/projects/vr-escaperoom/index.js" /* webpackChunkName: "component---src-pages-projects-vr-escaperoom-index-js" */),
  "component---src-pages-projects-web-development-index-js": () => import("./../../../src/pages/projects/web-development/index.js" /* webpackChunkName: "component---src-pages-projects-web-development-index-js" */),
  "component---src-pages-sentence-analyzer-index-js": () => import("./../../../src/pages/sentence-analyzer/index.js" /* webpackChunkName: "component---src-pages-sentence-analyzer-index-js" */)
}

